import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { getAddress } from 'ethers/lib/utils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TradeDirection } from "../../components/Exchange/types";
import { ENV, RUNTIME_ENV } from "../../constants/env";
import { addCustomLimitTokens, addCustomSpotTokens, addLimitTokens, addSpotTokens, removeTokensPriceList, updateConfig, updateV6Config, updateLimitOrderSubmitStatus, updateLimitOrderUserConfig, updateSpotSwapUserConfig, updateLimitSwapAmount, updateLimitSwapPair, updateLimitSwapPairAmount, updateLimitSwapPairToken, updateSpotSwapAmount, updateSpotSwapPair, updateSpotSwapPairAmount, updateSpotSwapPairToken, updateTokensPrice, updateTokensPriceList, updateSpotSwapUserTradeStatus, updateSpotSwapRecommendTokens } from "./actions";
import { LimitOrderExpiration, LimitOrderSubmitStatus, SpotSwapUserTradeStatus } from "./type";
export var initialState = {
  spotTokens: {},
  limitTokens: {},
  userCustomSpotTokens: {},
  userCustomLimitTokens: {},
  exchangePair: [null, null],
  spotSwapPair: [null, null],
  limitSwapPair: [null, null],
  limitSwapInputAmount: '',
  limitSwapOutputAmount: '',
  spotSwapInputAmount: '',
  spotSwapOutputAmount: '',
  prices: {},
  config: {},
  v6Config: {
    networkConfig: [],
    sanity: '0',
    slippageConfig: {
      min: 0.1,
      max: 1,
      step: 0.1,
      default: 0.5
    }
  },
  limitOrderSubmitStatus: LimitOrderSubmitStatus.Idle,
  limitOrderUserConfig: {
    expiration: LimitOrderExpiration.Day7,
    isInputRateDirection: true
  },
  spotSwapUserConfig: {
    sendByRelayer: false
  },
  spotSwapUserTradeStatus: SpotSwapUserTradeStatus.NORMAL,
  spotSwapRecommendTokens: []
};
export var dexReducer = createReducer(initialState, builder => builder.addCase(addSpotTokens, (state, _ref) => {
  var _ref$payload = _ref.payload,
      tokens = _ref$payload.tokens,
      chainId = _ref$payload.chainId;
  if (!state.spotTokens) state.spotTokens = {};
  state.spotTokens[chainId] = state.spotTokens[chainId] || {};
  tokens === null || tokens === void 0 ? void 0 : tokens.forEach(token => {
    state.spotTokens[chainId][token.address] = token;
  });
}).addCase(addCustomSpotTokens, (state, _ref2) => {
  var _ref2$payload = _ref2.payload,
      tokens = _ref2$payload.tokens,
      chainId = _ref2$payload.chainId;
  if (!state.userCustomSpotTokens) state.userCustomSpotTokens = {};
  state.userCustomSpotTokens[chainId] = state.userCustomSpotTokens[chainId] || {};
  tokens === null || tokens === void 0 ? void 0 : tokens.forEach(token => {
    state.userCustomSpotTokens[chainId][token.address] = token;
  });
}).addCase(addLimitTokens, (state, _ref3) => {
  var _ref3$payload = _ref3.payload,
      tokens = _ref3$payload.tokens,
      chainId = _ref3$payload.chainId;
  if (!state.limitTokens) state.limitTokens = {};
  state.limitTokens[chainId] = state.limitTokens[chainId] || {};
  tokens === null || tokens === void 0 ? void 0 : tokens.forEach(token => {
    state.limitTokens[chainId][token.address] = token;
  });
}).addCase(addCustomLimitTokens, (state, _ref4) => {
  var _ref4$payload = _ref4.payload,
      tokens = _ref4$payload.tokens,
      chainId = _ref4$payload.chainId;
  if (!state.userCustomLimitTokens) state.userCustomLimitTokens = {};
  state.userCustomLimitTokens[chainId] = state.userCustomLimitTokens[chainId] || {};
  tokens === null || tokens === void 0 ? void 0 : tokens.forEach(token => {
    state.userCustomLimitTokens[chainId][token.address] = token;
  });
}).addCase(updateSpotSwapPairToken, (state, _ref5) => {
  var _ref5$payload = _ref5.payload,
      direction = _ref5$payload.direction,
      token = _ref5$payload.token;
  if (!state.spotSwapPair) state.spotSwapPair = [];
  if (direction === TradeDirection.Input) state.spotSwapPair[0] = token;
  if (direction === TradeDirection.Output) state.spotSwapPair[1] = token;
}).addCase(updateSpotSwapPair, (state, _ref6) => {
  var _ref6$payload = _ref6.payload,
      inputToken = _ref6$payload.inputToken,
      outputToken = _ref6$payload.outputToken;
  state.spotSwapPair = [inputToken, outputToken];
}).addCase(updateLimitSwapPairToken, (state, _ref7) => {
  var _ref7$payload = _ref7.payload,
      direction = _ref7$payload.direction,
      token = _ref7$payload.token;
  if (!state.limitSwapPair) state.limitSwapPair = [];
  if (direction === TradeDirection.Input) state.limitSwapPair[0] = token;
  if (direction === TradeDirection.Output) state.limitSwapPair[1] = token;
}).addCase(updateLimitSwapAmount, (state, _ref8) => {
  var _ref8$payload = _ref8.payload,
      direction = _ref8$payload.direction,
      amount = _ref8$payload.amount;
  if (direction === TradeDirection.Input) state.limitSwapInputAmount = amount;
  if (direction === TradeDirection.Output) state.limitSwapOutputAmount = amount;
}).addCase(updateSpotSwapAmount, (state, _ref9) => {
  var _ref9$payload = _ref9.payload,
      direction = _ref9$payload.direction,
      amount = _ref9$payload.amount;
  if (direction === TradeDirection.Input) state.spotSwapInputAmount = amount;
  if (direction === TradeDirection.Output) state.spotSwapOutputAmount = amount;
}).addCase(updateSpotSwapPairAmount, (state, _ref10) => {
  var _ref10$payload = _ref10.payload,
      inputAmount = _ref10$payload.inputAmount,
      outputAmount = _ref10$payload.outputAmount;
  state.spotSwapInputAmount = inputAmount;
  state.spotSwapOutputAmount = outputAmount;
}).addCase(updateLimitSwapPairAmount, (state, _ref11) => {
  var _ref11$payload = _ref11.payload,
      inputAmount = _ref11$payload.inputAmount,
      outputAmount = _ref11$payload.outputAmount;
  state.limitSwapInputAmount = inputAmount;
  state.limitSwapOutputAmount = outputAmount;
}).addCase(updateLimitSwapPair, (state, _ref12) => {
  var _ref12$payload = _ref12.payload,
      inputToken = _ref12$payload.inputToken,
      outputToken = _ref12$payload.outputToken;
  state.limitSwapPair = [inputToken, outputToken];
}).addCase(updateConfig, (state, _ref13) => {
  var _ref13$payload = _ref13.payload,
      chainId = _ref13$payload.chainId,
      config = _ref13$payload.config;
  if (!state.config) state.config = {};
  state.config[chainId] = config;
}).addCase(updateV6Config, (state, _ref14) => {
  var v6Config = _ref14.payload.v6Config;
  state.v6Config = v6Config;
}).addCase(updateLimitOrderSubmitStatus, (state, _ref15) => {
  var payload = _ref15.payload;
  state.limitOrderSubmitStatus = payload;
}).addCase(updateLimitOrderUserConfig, (state, _ref16) => {
  var payload = _ref16.payload;
  if (!state.limitOrderUserConfig) state.limitOrderUserConfig = {};
  Object.keys(payload).forEach(key => {
    state.limitOrderUserConfig[key] = payload[key];
  });
}).addCase(updateSpotSwapUserConfig, (state, _ref17) => {
  var payload = _ref17.payload;
  if (!state.spotSwapUserConfig) state.spotSwapUserConfig = {};
  Object.keys(payload).forEach(key => {
    state.spotSwapUserConfig[key] = payload[key];
  });
}).addCase(updateSpotSwapRecommendTokens, (state, _ref18) => {
  var payload = _ref18.payload;
  state.spotSwapRecommendTokens = payload;
}).addCase(updateTokensPrice, (state, _ref19) => {
  var _ref19$payload = _ref19.payload,
      marketPrices = _ref19$payload.marketPrices,
      chainId = _ref19$payload.chainId,
      blockNumber = _ref19$payload.blockNumber,
      currency = _ref19$payload.currency;
  if (!state.prices) state.prices = {};
  if (!state.prices[chainId]) state.prices[chainId] = {};
  marketPrices.filter(p => Boolean(p.address)).forEach(p => {
    if (!state.prices[chainId][getAddress(p.address)]) state.prices[chainId][getAddress(p.address)] = {};
    var price = p.price || 0;

    if (!price && (ENV.development || ENV.local)) {
      price = Number(p.address) % 100 + new Date().getMinutes();
    }

    state.prices[chainId][getAddress(p.address)][currency] = {
      price: String(price),
      lastUpdateBlockNumber: blockNumber
    };
  });
}).addCase(updateTokensPriceList, (state, _ref20) => {
  var _ref20$payload = _ref20.payload,
      chainId = _ref20$payload.chainId,
      addresses = _ref20$payload.addresses,
      currency = _ref20$payload.currency;
  if (!state.prices) state.prices = [];
  if (!state.prices[chainId]) state.prices[chainId] = {};
  addresses.forEach(addr => {
    var address = getAddress(addr);
    if (state.prices[chainId][address]) return;
    state.prices[chainId][address] = {
      [currency]: {}
    };
  });
}).addCase(removeTokensPriceList, (state, _ref21) => {
  var _ref21$payload = _ref21.payload,
      addresses = _ref21$payload.addresses,
      chainId = _ref21$payload.chainId,
      currency = _ref21$payload.currency;
  if (!state.prices) return;
  if (!state.prices[chainId]) return;
  addresses.forEach(addr => {
    var address = getAddress(addr);

    if (state.prices[chainId][address]) {
      delete state.prices[chainId][address];
    }
  });
}).addCase(updateSpotSwapUserTradeStatus, (state, _ref22) => {
  var status = _ref22.payload;
  if (!state.spotSwapUserTradeStatus) state.spotSwapUserTradeStatus = SpotSwapUserTradeStatus.NORMAL;
  state.spotSwapUserTradeStatus = status;
}).addMatcher(isAnyOf(updateLimitSwapPair, updateLimitSwapPairToken), state => {
  state.limitSwapInputAmount = initialState.limitSwapInputAmount;
  state.limitSwapOutputAmount = initialState.limitSwapOutputAmount;
}).addMatcher(isAnyOf(updateSpotSwapPair, updateSpotSwapPairToken), state => {
  state.spotSwapInputAmount = initialState.spotSwapInputAmount;
  state.spotSwapOutputAmount = initialState.spotSwapOutputAmount;
}));
export var dexPersistConfig = {
  key: "sub-dex-".concat(RUNTIME_ENV),
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: ['spotSwapInputAmount', 'spotSwapOutputAmount', 'limitSwapInputAmount', 'limitSwapOutputAmount', 'limitOrderSubmitStatus', 'limitOrderUserConfig']
};
export default persistReducer(dexPersistConfig, dexReducer);