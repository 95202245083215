import { ArbitrumOne, ArbitrumRinkeby, Base, BSC, Optimism, Polygon, zkSync } from "../model/chain/ethereum"; // Please update in time according to block time. https://dune.com/jacobdcastro/avg-block-times

export var getBlocksPerFetchForChainId = chainId => {
  switch (chainId) {
    case ArbitrumOne.chainId:
      return 24;

    case ArbitrumRinkeby.chainId:
      return 24;

    case Base.chainId:
      return 3;

    case BSC.chainId:
      return 2;

    case Optimism.chainId:
      return 3;

    case Polygon.chainId:
      return 3;

    case zkSync.chainId:
      return 6;

    default:
      return 1;
  }
};