import React from "react";
var __jsx = React.createElement;
import { useCallback } from 'react';
import { useNetworkOptions, useSpotSwapRouter, useLimitOrderRouter, useWETHRouter } from "../../../hooks";
import { useOneOfModalOpened } from "../../../state/application/hooks";
import { ApplicationModal } from "../../../state/application/reducer";
import { getChainName, getWETHSupportedNetworks, getSpotSwapSupportedNetworks, getLimitOrderSupportedNetworks } from "../../../utils/chain";
import { ENV } from "../../../constants/env";
import { Mainnet, Sepolia } from "../../../model/chain/ethereum";
import { StyledOption } from "./StyledOptions";
import Checked from "../../../assets/images/checked.svg";
export var NetworkConnectOptions = _ref => {
  var onSelect = _ref.onSelect,
      chainId = _ref.chainId,
      containerStyle = _ref.containerStyle;
  var networks = useNetworkOptions();
  var isSpotSwapRouter = useSpotSwapRouter();
  var isLimitRouter = useLimitOrderRouter();
  var isWETHRouter = useWETHRouter();
  var wethWrapOpened = useOneOfModalOpened([ApplicationModal.WETH_WRAP, ApplicationModal.WETH_PREVIEW, ApplicationModal.WETH_RESULT]);
  var disable = useCallback(chainId => {
    if (isWETHRouter || wethWrapOpened) {
      var WETHSupportedChains = getWETHSupportedNetworks();
      return !WETHSupportedChains.includes(chainId);
    } else if (isSpotSwapRouter) {
      var SpotSwapSupportedChains = getSpotSwapSupportedNetworks();
      return !SpotSwapSupportedChains.includes(chainId);
    } else if (isLimitRouter) {
      var LOSupportedChains = getLimitOrderSupportedNetworks();
      return !LOSupportedChains.includes(chainId);
    } else {
      var isDev = ENV.development || ENV.local;
      var defaultSupportedChainId = isDev ? Sepolia.chainId : Mainnet.chainId;
      return defaultSupportedChainId !== chainId;
    }
  }, [isSpotSwapRouter, isLimitRouter, isWETHRouter, wethWrapOpened]);
  return __jsx("div", {
    style: containerStyle
  }, networks.map(chain => __jsx(StyledOption, {
    disabled: disable(chain.chainId),
    key: chain.chainId,
    onClick: () => onSelect(chain.chainId)
  }, __jsx("span", null, __jsx("img", {
    src: chain.logoUrl,
    alt: getChainName(chain.chainId),
    className: "logo"
  }), getChainName(chain.chainId)), Number(chain.chainId) === Number(chainId) ? __jsx("img", {
    src: Checked,
    alt: "",
    className: "option-checked"
  }) : __jsx("span", {
    className: "option-unchecked"
  }))));
};