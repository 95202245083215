import styled from 'styled-components';
export var StyledOptionsContainer = styled.div.withConfig({
  displayName: "StyledOptionsContainer",
  componentId: "sc-yiretj-0"
})(["padding:12px 16px;border-radius:16px;background:#fafafc;"]);
export var StyledOption = styled.button.withConfig({
  displayName: "StyledOption",
  componentId: "sc-yiretj-1"
})(["background:", ";border:1px solid #eff0fa;box-sizing:border-box;border-radius:50px;font-weight:500;font-size:15px;line-height:16px;color:", ";padding:10px 17px;width:100%;display:flex;align-items:center;justify-content:space-between;cursor:", ";transition:background-color ease-in-out 0.2s;&:hover{background:#f5f7fb;}&:not(:last-of-type){margin-bottom:10px;}img{opacity:", ";}.logo{width:24px;height:24px;margin-right:9px;}.indicator{margin-left:auto;width:16px;height:16px;}.option-checked,.option-unchecked{margin-left:8px;}.option-unchecked{border:1px solid #eff0fa;border-radius:50%;width:15px;height:15px;}"], _ref => {
  var disabled = _ref.disabled;
  return disabled ? '#FAFAFC' : '#ffffff';
}, _ref2 => {
  var disabled = _ref2.disabled;
  return disabled ? '#D2D7E5' : '#1A1C1E';
}, _ref3 => {
  var disabled = _ref3.disabled;
  return disabled ? 'not-allowed' : 'pointer';
}, _ref4 => {
  var disabled = _ref4.disabled;
  return disabled ? '0.2' : '1';
});
export var StyledShield = styled.img.withConfig({
  displayName: "StyledShield",
  componentId: "sc-yiretj-2"
})(["width:16px;height:18px;margin-right:8px;"]);
export var StyledOptionsLabel = styled.div.withConfig({
  displayName: "StyledOptionsLabel",
  componentId: "sc-yiretj-3"
})(["margin:0 0 8px 16px;font-size:13px;font-weight:500;line-height:14px;color:#9598ab;"]);