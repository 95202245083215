import { useMemo } from 'react';
import { useCurrentChainId } from "./useCurrentChainId";
import { useWETHRouter, useSpotSwapRouter, useLimitOrderRouter } from "./";
import { useOneOfModalOpened } from "../state/application/hooks";
import { ApplicationModal } from "../state/application/reducer";
import { getCommonUpdaterChainId, getWETHSupportedNetworks, getSpotSwapSupportedNetworks, getLimitOrderSupportedNetworks } from "../utils/chain";
export var useIsValidNetwork = () => {
  var chainId = useCurrentChainId();
  var isWETHRouter = useWETHRouter();
  var isSpotSwapRouter = useSpotSwapRouter();
  var isLimitRouter = useLimitOrderRouter();
  var wethWrapOpened = useOneOfModalOpened([ApplicationModal.WETH_WRAP, ApplicationModal.WETH_PREVIEW, ApplicationModal.WETH_RESULT]);
  return useMemo(() => {
    if (isWETHRouter || wethWrapOpened) {
      var WETHSupportedChains = getWETHSupportedNetworks();
      return WETHSupportedChains.includes(chainId);
    } else if (isSpotSwapRouter) {
      var SpotSwapSupportedChains = getSpotSwapSupportedNetworks();
      return SpotSwapSupportedChains.includes(chainId);
    } else if (isLimitRouter) {
      var LOSupportedChains = getLimitOrderSupportedNetworks();
      return LOSupportedChains.includes(chainId);
    } else {
      var defaultSupportedChainId = getCommonUpdaterChainId();
      return defaultSupportedChainId === chainId;
    }
  }, [isLimitRouter, isWETHRouter, isSpotSwapRouter, wethWrapOpened, chainId]);
};