import { ALL_SUPPORTED_CHAINS, ALL_SUPPORTED_CHAIN_IDS, EXTRA_CONNECT_CHAINS } from "../constants/chain";
import { ENV } from "../constants/env";
import { Mainnet, Sepolia, Polygon, ArbitrumOne, ArbitrumRinkeby } from "../model/chain/ethereum";
export var getChainById = chainId => ALL_SUPPORTED_CHAINS.find(network => network.chainId === chainId);
export var getExtraConnectChainById = chainId => EXTRA_CONNECT_CHAINS.find(network => network.chainId === chainId);
export var getExplorerAddressLink = (address, chainId, locale) => {
  var _getChainById;

  return ((_getChainById = getChainById(chainId)) === null || _getChainById === void 0 ? void 0 : _getChainById.getExplorerAddressLink(address, locale)) || '';
};
export var getExplorerTransactionLink = (transactionHash, chainId, locale) => {
  var _getChainById2;

  return ((_getChainById2 = getChainById(chainId)) === null || _getChainById2 === void 0 ? void 0 : _getChainById2.getExplorerTransactionLink(transactionHash, locale)) || '';
};
export var getExplorerTokenLink = (address, chainId, locale) => {
  var _getChainById3;

  return ((_getChainById3 = getChainById(chainId)) === null || _getChainById3 === void 0 ? void 0 : _getChainById3.getExplorerTokenLink(address, locale)) || '';
};
export var getChainName = chainId => {
  var _getChainById4, _getExtraConnectChain;

  // When metamask adds a network, chainName uses Polygon Mainnet to avoid metamask warnings, but the Tokenlon website wants to be displayed only as Polygon.
  if (chainId === Polygon.chainId) {
    return 'Polygon';
  }

  return ((_getChainById4 = getChainById(chainId)) === null || _getChainById4 === void 0 ? void 0 : _getChainById4.chainName) || ((_getExtraConnectChain = getExtraConnectChainById(chainId)) === null || _getExtraConnectChain === void 0 ? void 0 : _getExtraConnectChain.chainName) || 'Unsupported Chain';
};
export var isTestChain = chainId => ALL_SUPPORTED_CHAINS.some(network => network.isTestChain && network.chainId === chainId);
export var isLocalChain = chainId => ALL_SUPPORTED_CHAINS.some(network => network.isLocalChain && network.chainId === chainId);
export var isSupportedChain = chainId => ALL_SUPPORTED_CHAIN_IDS.includes(chainId);
export var getNetworkSupportedChain = env => ALL_SUPPORTED_CHAINS.filter(network => network.matchedEnv.includes(env));
export var getNetworkLogo = chainId => {
  var _getChainById$logoUrl, _getChainById5;

  return (_getChainById$logoUrl = (_getChainById5 = getChainById(chainId)) === null || _getChainById5 === void 0 ? void 0 : _getChainById5.logoUrl) !== null && _getChainById$logoUrl !== void 0 ? _getChainById$logoUrl : null;
};
export var getCommonUpdaterChainId = () => {
  var isDev = ENV.development || ENV.local;
  return isDev ? Sepolia.chainId : Mainnet.chainId;
};
export var isMainnetOrSepolia = chainId => {
  return chainId === Mainnet.chainId || chainId === Sepolia.chainId;
};
export var getWETHSupportedNetworks = () => {
  var isDev = ENV.development || ENV.local;
  return isDev ? [Sepolia.chainId, ArbitrumRinkeby.chainId] : [Mainnet.chainId, ArbitrumOne.chainId];
};
export var getSpotSwapSupportedNetworks = () => {
  var isDev = ENV.development || ENV.local;
  return isDev ? ALL_SUPPORTED_CHAIN_IDS.filter(chainId => chainId !== Mainnet.chainId && chainId !== ArbitrumOne.chainId) : ALL_SUPPORTED_CHAIN_IDS.filter(chainId => chainId !== Sepolia.chainId && chainId !== ArbitrumRinkeby.chainId);
};
export var getLimitOrderSupportedNetworks = () => {
  var isDev = ENV.development || ENV.local;
  return isDev ? [Sepolia.chainId, ArbitrumRinkeby.chainId] : [Mainnet.chainId, ArbitrumOne.chainId];
};