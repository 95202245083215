import { SELECTABLE_CONNECTIONS } from "../connection";
import { detectMetaMaskWallet, detectImTokenApp } from "../connection/detect";
import { useBreakpoint } from "./useMediaQuery";
export var useConnectionOptions = () => {
  var breakpoints = useBreakpoint();
  var isMobile = breakpoints.isMobile;
  var isImTokenApp = detectImTokenApp();
  var isMetaMaskWallet = detectMetaMaskWallet();
  var isDesktop = !isMobile && !isImTokenApp;
  var options = SELECTABLE_CONNECTIONS.filter(connection => {
    if (typeof connection.shouldDisplay === 'function') {
      return connection.shouldDisplay(breakpoints, {
        isDesktop,
        isImTokenApp,
        isMetaMaskWallet
      });
    }
  });
  return options;
};