import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import Modal from "../../Modal";
import styled from 'styled-components';
import IconWarning from "../../../assets/images/warning-black.svg";

var HoTWarningModal = _ref => {
  var isOpen = _ref.isOpen,
      onConfirm = _ref.onConfirm,
      onClose = _ref.onClose;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(Modal, {
    title: t('hot_warning_modal.title'),
    isOpen: isOpen,
    onDismiss: onClose,
    animate: false,
    contentStyle: {
      maxWidth: '500px'
    }
  }, __jsx(StyledContainer, null, __jsx(StyledContent, null, __jsx(StyledIcon, {
    src: IconWarning,
    alt: "warning"
  }), __jsx("p", null, t('hot_warning_modal.content_desc'))), __jsx(StyledBtn, {
    onClick: onConfirm
  }, t('hot_warning_modal.connect_btn'))));
};

export default HoTWarningModal;
var StyledContainer = styled.div.withConfig({
  displayName: "StyledContainer",
  componentId: "sc-13nphzz-0"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;padding:0 16px 16px;"]);
var StyledContent = styled.div.withConfig({
  displayName: "StyledContent",
  componentId: "sc-13nphzz-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;gap:8px;padding:24px;border-radius:20px;background-color:#f7f7f9;p{margin:0;font-size:14px;font-weight:400;color:#81808d;}"]);
var StyledIcon = styled.img.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-13nphzz-2"
})(["width:24px;height:24px;"]);
var StyledBtn = styled.button.withConfig({
  displayName: "StyledBtn",
  componentId: "sc-13nphzz-3"
})(["display:flex;justify-content:center;align-items:center;width:100%;max-width:300px;margin-top:20px;padding:12px 0;border-radius:48px;border:none;outline:none;background:", ";font-weight:500;font-size:16px;line-height:22px;color:#ffffff;cursor:pointer;"], _ref2 => {
  var theme = _ref2.theme;
  return theme.colors.btnPrimary;
});