import TokenWebView from '@consenlabs-fe/webview';
export var detectMetaMaskWallet = () => {
  var _window$ethereum$isMe, _window$ethereum;

  return (_window$ethereum$isMe = (_window$ethereum = window.ethereum) === null || _window$ethereum === void 0 ? void 0 : _window$ethereum.isMetaMask) !== null && _window$ethereum$isMe !== void 0 ? _window$ethereum$isMe : false;
};
export var detectImTokenApp = () => TokenWebView.isTokenEnv();
export var isCoinbaseWallet = () => {
  var _window$ethereum$isCo, _window$ethereum2;

  return (_window$ethereum$isCo = (_window$ethereum2 = window.ethereum) === null || _window$ethereum2 === void 0 ? void 0 : _window$ethereum2.isCoinbaseWallet) !== null && _window$ethereum$isCo !== void 0 ? _window$ethereum$isCo : false;
};
export var isSafari = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};
export var isFirefox = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('firefox');
};