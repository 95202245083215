import Axios from 'axios';
import { captureException } from '@sentry/nextjs';
import { RUNTIME_ENV, TokenlonEnv } from "../constants/env";
import { API_ROOT_DOMAIN } from "../constants/rootDomain";
export var API_BASE_URL = "https://api.".concat(API_ROOT_DOMAIN, "/v1");
export var MARKET_API_BASE_URL = "https://tokenlon-market.".concat(API_ROOT_DOMAIN, "/rpc");
export var AUTH_TOKEN = {
  [TokenlonEnv.development]: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkYwNEE4RTgxLTkzNDItNDM0OS1BRkRFLTRCMjYwQTkyRkEzOSIsImp0aSI6ImltMTR4NTdLQ3hIeldyUDhtSzFVTTJLN2VBSm5UVG9jOVhuWEp4ViJ9.L7cX_cxbisH12q1wtivtMEm6FC3hSWMkjwVE7o_z-Hw',
  [TokenlonEnv.local]: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkYwNEE4RTgxLTkzNDItNDM0OS1BRkRFLTRCMjYwQTkyRkEzOSIsImp0aSI6ImltMTR4NTdLQ3hIeldyUDhtSzFVTTJLN2VBSm5UVG9jOVhuWEp4ViJ9.L7cX_cxbisH12q1wtivtMEm6FC3hSWMkjwVE7o_z-Hw',
  [TokenlonEnv.staging]: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkVBQjZBRTJELThFNEYtNEFDMS1CODM4LTA5MkQwMzE2NjlGQSIsImp0aSI6ImltMTR4NUxZck11Q1lxaXdTRzVBeFhaOXlGRDlIdml2VmJKdDVMRiJ9.rkJ2jziqRKwHvUKX2xkrkA2CDppGegElgVuZ2syHf5Y',
  [TokenlonEnv.production]: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkVBQjZBRTJELThFNEYtNEFDMS1CODM4LTA5MkQwMzE2NjlGQSIsImp0aSI6ImltMTR4NUxZck11Q1lxaXdTRzVBeFhaOXlGRDlIdml2VmJKdDVMRiJ9.rkJ2jziqRKwHvUKX2xkrkA2CDppGegElgVuZ2syHf5Y'
}[RUNTIME_ENV];
export var CORE_MARKET_URL = 'https://tokenlon-core-market.tokenlon.im/rpc';
var axios = Axios.create({
  baseURL: API_BASE_URL
});
axios.interceptors.response.use(response => response.data, error => {
  captureException(error);
  return Promise.reject(error);
});
var rpc = Axios.create({
  baseURL: API_BASE_URL
});
rpc.interceptors.response.use(response => response.data.result, error => {
  captureException(error);
  return Promise.reject(error);
});
export { axios, rpc };