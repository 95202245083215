import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { useModalToggle, useModalOpened } from "../../../state/application/hooks";
import { ApplicationModal } from "../../../state/application/reducer";
import Modal from "../../Modal";
import { LOCALES } from "../../../locales";
import styled from 'styled-components';
import IconWarning from "../../../assets/images/warning-black.svg";

var ImKeyUnSupportedModal = () => {
  var isOpen = useModalOpened(ApplicationModal.IMKEY_UNSUPPORTED);
  var toggleModal = useModalToggle(ApplicationModal.IMKEY_UNSUPPORTED);

  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;
  return __jsx(Modal, {
    title: t('imkey_unsupported_modal.title'),
    isOpen: isOpen,
    onDismiss: toggleModal,
    animate: false,
    contentStyle: {
      maxWidth: '500px'
    }
  }, __jsx(StyledContainer, null, __jsx(StyledContent, null, __jsx(StyledIcon, {
    src: IconWarning,
    alt: "warning"
  }), __jsx(StyledTitle, null, t('imkey_unsupported_modal.content_title')), __jsx("p", null, t('imkey_unsupported_modal.content_desc'))), __jsx(StyledLearnMore, null, __jsx("span", null, t('imkey_unsupported_modal.learn_more')), !isZh && __jsx("span", null, "\xA0"), __jsx(StyledLink, {
    href: 'https://imkey.im',
    target: "_blank"
  }, t('learn_more')), __jsx("span", null, t('period')))));
};

export default ImKeyUnSupportedModal;
var StyledContainer = styled.div.withConfig({
  displayName: "StyledContainer",
  componentId: "sc-ugklas-0"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;padding:0 16px 16px;"]);
var StyledContent = styled.div.withConfig({
  displayName: "StyledContent",
  componentId: "sc-ugklas-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;gap:8px;padding:24px;border-radius:20px;background-color:#f7f7f9;p{margin:0;font-size:14px;font-weight:400;color:#81808d;}"]);
var StyledTitle = styled.p.withConfig({
  displayName: "StyledTitle",
  componentId: "sc-ugklas-2"
})(["font-size:16px;font-weight:600;color:#3c3c44;"]);
var StyledIcon = styled.img.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-ugklas-3"
})(["width:24px;height:24px;"]);
var StyledLearnMore = styled.div.withConfig({
  displayName: "StyledLearnMore",
  componentId: "sc-ugklas-4"
})(["margin-top:20px;font-size:12px;font-weight:400;color:#81808d;"]);
var StyledLink = styled.a.withConfig({
  displayName: "StyledLink",
  componentId: "sc-ugklas-5"
})(["color:", ";cursor:pointer;:hover{color:", ";}"], _ref => {
  var theme = _ref.theme;
  return theme.colors.linkPrimary;
}, _ref2 => {
  var theme = _ref2.theme;
  return theme.colors.linkPrimary;
});