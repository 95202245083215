import { useWeb3React } from '@web3-react/core';
export var useIsHoT = () => {
  var _provider$provider;

  var _useWeb3React = useWeb3React(),
      provider = _useWeb3React.provider;

  if (!provider) {
    return false;
  } // @ts-ignore


  return !!(provider !== null && provider !== void 0 && (_provider$provider = provider.provider) !== null && _provider$provider !== void 0 && _provider$provider.isHoT);
};