import { AddressZero } from '@ethersproject/constants';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { getAddress } from 'ethers/lib/utils';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ENV } from "../constants/env";
import { marketRpc } from "../services/rpc/tokenlon";
import { useBlockNumber } from "../state/application/hooks";
import { updateTokensPrice, updateTokensPriceList } from "../state/dex/actions";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { Currency, FixedPointNumber } from "../utils/fixedPoint";
import { NETWORK_POLLING_INTERVALS } from "../utils/getLibrary";
import useUpdaterByBlock from "./useUpdaterByBlock";
import { useUpdaterChainId } from "./web3";
export var useTokensPriceStandalone = function useTokensPriceStandalone(tokenAddresses) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
  var blocksPerFetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5;

  var _useState = useState({}),
      prices = _useState[0],
      setPrices = _useState[1];

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var blockNumber = useBlockNumber();
  var blocksPerFetchRef = useRef(0);
  useEffect(() => {
    if (blocksPerFetch === 0) return;

    if (!blocksPerFetchRef.current) {
      blocksPerFetchRef.current = blockNumber;
      return;
    }

    if (blockNumber - blocksPerFetchRef.current > blocksPerFetch) {
      blocksPerFetchRef.current = blockNumber;
    }
  }, [blockNumber]);
  useEffect(() => {
    marketRpc.getMarketPrice(tokenAddresses, chainId, currency).then(res => {
      var p = {};
      res.map(t => {
        p[t.address.toLowerCase()] = t === null || t === void 0 ? void 0 : t.price;

        if (!(t !== null && t !== void 0 && t.price)) {
          p[t.address.toLowerCase()] = prices[t.address.toLowerCase()] || 0;
        }

        if (ENV.development || ENV.local) {
          p[t.address.toLowerCase()] = t.address % 100 + 1;
        }
      });
      setPrices(p);
    }).catch(err => {
      console.error(err);
      setPrices({});
    });
  }, [chainId, JSON.stringify(tokenAddresses), blocksPerFetchRef.current]);
  return prices;
};
export var useTokenPriceStandalone = function useTokenPriceStandalone(tokenAddress) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
  var blocksPerFetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var prices = useTokensPriceStandalone([tokenAddress], currency, blocksPerFetch);
  return prices[tokenAddress === null || tokenAddress === void 0 ? void 0 : tokenAddress.toLowerCase()] || 0;
};
export var useTokenUSDPriceWithAmount = function useTokenUSDPriceWithAmount(tokenAddress) {
  var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0';
  var currency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Currency.USD;
  var fixedAt = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;
  var price = useTokenPrice(tokenAddress, 'USD');

  if (currency) {
    return new FixedPointNumber(new BigNumber(amount).times(price)).toCurrency(currency, fixedAt);
  }

  return new FixedPointNumber(new BigNumber(amount).times(price));
};
export var useTokenPriceCallback = () => {
  var expectChainId = useUpdaterChainId();
  var tokens = useAppSelector(state => state.dex.prices);
  var updateTokens = useMemo(() => tokens[expectChainId], [expectChainId, tokens]);
  var dispatch = useAppDispatch();
  var tokenAddresses = useMemo(() => Object.keys(updateTokens || {}), [updateTokens]);
  return useCallback(function (blockNumber) {
    var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
    if (!tokenAddresses) return;
    marketRpc.getMarketPrice(tokenAddresses, expectChainId, currency).then(res => dispatch(updateTokensPrice({
      chainId: expectChainId,
      marketPrices: res,
      blockNumber,
      currency
    })));
  }, [tokenAddresses.toString()]);
};
export var TokenPriceUpdater = () => {
  var updaterCallback = useTokenPriceCallback();
  var expectChainId = useUpdaterChainId();
  var blocksPerFetch = useMemo(() => {
    if (NETWORK_POLLING_INTERVALS[expectChainId]) {
      return 10 * NETWORK_POLLING_INTERVALS[expectChainId] / 1000;
    }

    return 10;
  }, [expectChainId]);
  useUpdaterByBlock(updaterCallback, blocksPerFetch);
  return null;
};
export var useTokensPrice = function useTokensPrice(addresses) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
  var chainId = arguments.length > 2 ? arguments[2] : undefined;
  var dispatch = useAppDispatch();
  var expectChainId = useUpdaterChainId();
  var targetChainId = chainId !== null && chainId !== void 0 ? chainId : expectChainId;
  var prices = useAppSelector(state => state.dex.prices);
  useEffect(() => {
    dispatch(updateTokensPriceList({
      addresses,
      currency,
      chainId: targetChainId
    }));
  }, [targetChainId, JSON.stringify(addresses)]);
  return useMemo(() => {
    if (!prices || !prices[targetChainId]) return {};
    var p = {};
    addresses.forEach(addr => {
      var address = getAddress(addr);
      var price = get(prices[targetChainId][address], "".concat(currency, ".price"), 0);
      p[address] = price;

      if ((ENV.local || ENV.development) && address === AddressZero) {
        p[address] = '1250';
      }
    });
    return p;
  }, [JSON.stringify(prices === null || prices === void 0 ? void 0 : prices[targetChainId]), JSON.stringify(addresses), currency]);
};
export var useTokenPrice = function useTokenPrice(tokenAddress) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'USD';
  var prices = useTokensPrice([tokenAddress].filter(Boolean), currency);
  if (!tokenAddress) return 0;
  return prices[getAddress(tokenAddress)] || 0;
};