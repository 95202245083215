import { useMemo } from 'react';
import { useWETHRouter, useSpotSwapRouter, useLimitOrderRouter } from "./";
import { useOneOfModalOpened } from "../state/application/hooks";
import { ApplicationModal } from "../state/application/reducer";
import { useCurrentChainId } from "./useCurrentChainId";
import { getCommonUpdaterChainId, getWETHSupportedNetworks, getSpotSwapSupportedNetworks, getLimitOrderSupportedNetworks } from "../utils/chain"; // 保证用于 updater 的 chainId 永远是对的，和用户的 chainId 无关

export var useUpdaterChainId = () => {
  var isWETHRouter = useWETHRouter();
  var isSpotSwapRouter = useSpotSwapRouter();
  var isLimitOrderRouter = useLimitOrderRouter();
  var wethWrapOpened = useOneOfModalOpened([ApplicationModal.WETH_WRAP, ApplicationModal.WETH_PREVIEW, ApplicationModal.WETH_RESULT]);
  var currentChainId = useCurrentChainId();
  var defaultChainId = getCommonUpdaterChainId();
  return useMemo(() => {
    if (isWETHRouter || wethWrapOpened) {
      var wethSupportedChains = getWETHSupportedNetworks();
      return wethSupportedChains.includes(currentChainId) ? currentChainId : defaultChainId;
    } else if (isSpotSwapRouter) {
      var spotSwapSupportedChains = getSpotSwapSupportedNetworks();
      return spotSwapSupportedChains.includes(currentChainId) ? currentChainId : defaultChainId;
    } else if (isLimitOrderRouter) {
      var loSupportedChains = getLimitOrderSupportedNetworks();
      return loSupportedChains.includes(currentChainId) ? currentChainId : defaultChainId;
    } else {
      return defaultChainId;
    }
  }, [isWETHRouter, isSpotSwapRouter, isLimitOrderRouter, wethWrapOpened, currentChainId]);
};