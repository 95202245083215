import styled, { css } from 'styled-components';
export var StyledContainer = styled.div.withConfig({
  displayName: "StyledContainer",
  componentId: "sc-y8q8lc-0"
})(["position:relative;"]);
export var ImageLogo = styled.img.withConfig({
  displayName: "ImageLogo",
  componentId: "sc-y8q8lc-1"
})(["width:28px;height:28px;display:inline-block;border-radius:50%;margin:", "px;background:#fff;", " ", ""], _ref => {
  var space = _ref.space;
  return typeof space === 'undefined' ? '5' : space;
}, _ref2 => {
  var large = _ref2.large;
  return large && css(["width:36px;height:36px;"]);
}, _ref3 => {
  var size = _ref3.size;
  return typeof size !== 'undefined' && css(["width:", "px;height:", "px;"], size, size);
});
export var TextLogo = styled.span.withConfig({
  displayName: "TextLogo",
  componentId: "sc-y8q8lc-2"
})(["background:#c4c9d9;color:#ffffff;font-size:10px;text-align:center;width:28px;height:28px;line-height:28px;display:inline-block;border-radius:50%;letter-spacing:-0.3px;margin:", "px;", " ", ""], _ref4 => {
  var space = _ref4.space;
  return typeof space === 'undefined' ? '5' : space;
}, _ref5 => {
  var large = _ref5.large;
  return large && css(["width:36px;height:36px;line-height:32px;border:2px solid white;"]);
}, _ref6 => {
  var size = _ref6.size;
  return typeof size !== 'undefined' && css(["width:", "px;height:", "px;line-height:", "px;border:2px solid white;"], size, size, size - 4);
});
export var NetworkBadge = styled.img.withConfig({
  displayName: "NetworkBadge",
  componentId: "sc-y8q8lc-3"
})(["position:absolute;top:16px;left:17px;width:16px;height:16px;border-radius:50%;border:1px solid #ffffff;background:#fff;", ""], _ref7 => {
  var large = _ref7.large;
  return large && css(["top:23px;left:22px;width:18px;height:18px;"]);
});