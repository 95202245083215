import React from "react";
var __jsx = React.createElement;
import { useWeb3React } from '@web3-react/core';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddressZero } from '@ethersproject/constants';
import { useModalOpened, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import IconCopy from "../../assets/images/copy-bordered-black.svg";
import { message } from "../Message";
import Modal from "../Modal";
import { Column, Box, RowBetween } from "../Layout";
import { Type } from "../../theme";
import { TokenInfo } from "./TokenInfo";
import { MenuItem } from "./MenuItem";
import IconLON from "../../assets/images/lon.svg";
import IconMenuRecords from "../../assets/images/menu-clock.svg";
import IconMenuSettings from "../../assets/images/menu-settings.svg";
import IconMenuDisconnections from "../../assets/images/menu-disconnect.svg";
import { useChain } from "../../hooks/useContract";
import { getExplorerAddressLink, getCommonUpdaterChainId, isMainnetOrSepolia } from "../../utils/chain";
import { useFeeFactor } from "../../state/user/hooks";
import { useAppDispatch } from "../../state/hooks";
import { updateSelectedConnectionType, updateWalletType } from "../../state/user/actions";
import { useNativeCurrencySymbol, useNativeCurrencyLogo } from "../../state/wallet/hooks";
import IconArrow from "../../assets/images/arrow-right-purple-light.svg";
import { LOCALES } from "../../locales";
import { useGA, useUpdaterChainId } from "../../hooks";
import { useTokenlonV6NetworkConfig } from "../../state/dex/hooks";
var Icon = styled.img.withConfig({
  displayName: "Icon",
  componentId: "sc-1qjlaw4-0"
})(["width:28px;margin-right:10px;border-radius:50%;cursor:pointer;"]);

var AddressInfo = () => {
  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var _useGA = useGA(),
      trackElClickCurring = _useGA.trackElClickCurring,
      trackElClick = _useGA.trackElClick;

  var expectedChainId = useUpdaterChainId();
  var isMainnetOrSepoliaFlag = isMainnetOrSepolia(expectedChainId);
  var defaultL1ChainId = getCommonUpdaterChainId();
  var l1FeeFactor = useFeeFactor(defaultL1ChainId);

  var _useTokenlonV6Network = useTokenlonV6NetworkConfig(),
      _useTokenlonV6Network2 = _useTokenlonV6Network.feeFactor,
      configFeeFactor = _useTokenlonV6Network2 === void 0 ? 15 : _useTokenlonV6Network2;

  var l2FeeFactor = isMainnetOrSepoliaFlag ? 15 : configFeeFactor;
  var mainnetFeeLabel = l1FeeFactor === 30 ? t('default') : t('discounted_trade_fee');
  var mainnetFeeLiteral = "".concat(t('mainnet'), " ").concat(l1FeeFactor / 100, "% (").concat(mainnetFeeLabel, ")");
  var multiFeeLiteral = "".concat(t('multi_network'), " ").concat(l2FeeFactor / 100, "%");
  var lang = i18n.resolvedLanguage === LOCALES.ZH ? 'zh-cn' : 'en-us';
  var FeeIntroLink = "https://support.tokenlon.im/hc/".concat(lang, "/articles/360037260272");

  var copyAddr = () => {
    copy(account);
    message.success(t('copy_success'));
  };

  return __jsx(Box, {
    mb: "18px",
    borderRadius: "16px",
    padding: "20px 16px",
    backgroundColor: "#36305B"
  }, __jsx(Column, {
    style: {
      width: '100%'
    }
  }, __jsx(Type.Label, null, t('wallet_address')), __jsx(RowBetween, {
    "data-trackid": "webtl_mywallet_copy",
    onClick: e => trackElClickCurring(e)(copyAddr),
    borderRadius: "8px",
    padding: "8px",
    margin: "8px 0 12px",
    backgroundColor: "#3F3965"
  }, __jsx(Box, {
    pointer: true,
    width: "80%",
    overflowWrap: "anywhere"
  }, __jsx(Type.Address, {
    style: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#fff',
      wordBreak: 'break-all'
    }
  }, account)), __jsx(Icon, {
    src: IconCopy
  })), __jsx(RowBetween, {
    margin: "5px 0 0"
  }, __jsx(Type.Label, null, t('info_swap_trade_fee')), __jsx("a", {
    href: FeeIntroLink,
    target: "_blank",
    "data-trackid": "webtl_mywallet_txfee",
    "data-trackdata": JSON.stringify({
      url: FeeIntroLink
    }),
    onClick: trackElClick
  }, __jsx(Type.Label, {
    style: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '13px',
      color: '#C0B4FF',
      cursor: 'pointer'
    }
  }, t('info_trade_fee_intro'), __jsx("img", {
    src: IconArrow
  })))), __jsx(Type.Label, {
    style: {
      marginTop: '5px',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#fff'
    }
  }, mainnetFeeLiteral), __jsx(Type.Label, {
    style: {
      marginTop: '5px',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#fff'
    }
  }, multiFeeLiteral)));
};

var TokenInfos = () => {
  var _useChain = useChain(),
      lonAddress = _useChain.lonAddress;

  var nativeSymbol = useNativeCurrencySymbol();
  var nativeCurrencyLogo = useNativeCurrencyLogo();

  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  return __jsx(Column, null, __jsx(TokenInfo, {
    icon: nativeCurrencyLogo,
    symbol: nativeSymbol,
    addr: AddressZero
  }), isMainnetOrSepolia(chainId) ? __jsx(TokenInfo, {
    icon: IconLON,
    symbol: "LON",
    addr: lonAddress
  }) : null);
};

var StyledControls = styled.div.withConfig({
  displayName: "StyledControls",
  componentId: "sc-1qjlaw4-1"
})(["box-shadow:rgb(0 0 0 / 5%) 0px 1px 0px inset;margin:-20px;margin-top:20px;padding:5px 20px;"]);
var ControlsMenu;

(function (ControlsMenu) {
  ControlsMenu[ControlsMenu["TX"] = 0] = "TX";
  ControlsMenu[ControlsMenu["ALLOWANCE"] = 1] = "ALLOWANCE";
  ControlsMenu[ControlsMenu["DISCONNECT"] = 2] = "DISCONNECT";
})(ControlsMenu || (ControlsMenu = {}));

var ControlsTrackId = {
  [ControlsMenu.TX]: 'webtl_mywallet_record',
  [ControlsMenu.ALLOWANCE]: 'webtl_mywallet_approve',
  [ControlsMenu.DISCONNECT]: 'webtl_mywallet_disconnect'
};

var Controls = () => {
  var _useTranslation2 = useTranslation(),
      t = _useTranslation2.t;

  var _useWeb3React3 = useWeb3React(),
      chainId = _useWeb3React3.chainId,
      account = _useWeb3React3.account,
      connector = _useWeb3React3.connector;

  var allowanceManagingUrl = "https://app.scamsniffer.io/permits/".concat(account);
  var dispatch = useAppDispatch();

  var _useGA2 = useGA(),
      trackClick = _useGA2.trackClick;

  var toggleInfoModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true
  });
  var toggleAllowanceListModal = useModalToggle(ApplicationModal.ALLOWANCE_LIST, {
    stack: true
  });

  var gotoTxRecords = () => {
    window.open(getExplorerAddressLink(account, chainId), '_blank');
  };

  var onDisconnect = () => {
    if (connector !== null && connector !== void 0 && connector.deactivate) {
      connector.deactivate();
    }

    connector.resetState();
    dispatch(updateSelectedConnectionType(null));
    dispatch(updateWalletType(null));
    toggleInfoModal();
  };

  var onMenuClick = menu => {
    trackClick(ControlsTrackId[menu]);

    switch (menu) {
      case ControlsMenu.TX:
        gotoTxRecords();
        break;

      case ControlsMenu.ALLOWANCE:
        toggleAllowanceListModal();
        break;

      case ControlsMenu.DISCONNECT:
        onDisconnect();
        break;
    }
  };

  return __jsx(React.Fragment, null, __jsx(StyledControls, null, __jsx(RowBetween, null, __jsx(MenuItem, {
    icon: IconMenuRecords,
    title: t('tokenlon_records'),
    onClick: () => onMenuClick(ControlsMenu.TX)
  }), __jsx("a", {
    href: allowanceManagingUrl
  }, __jsx(MenuItem, {
    icon: IconMenuSettings,
    title: t('allowance_manage'),
    onClick: () => {}
  })), __jsx(MenuItem, {
    icon: IconMenuDisconnections,
    title: t('disconnect'),
    onClick: () => onMenuClick(ControlsMenu.DISCONNECT)
  }))));
};

export var InfoModal = () => {
  var _useTranslation3 = useTranslation(),
      t = _useTranslation3.t;

  var isOpen = useModalOpened(ApplicationModal.WALLET_INFO);
  var toggleModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true
  });
  return __jsx(Modal, {
    isOpen: isOpen,
    title: t('my_wallet'),
    onDismiss: toggleModal
  }, __jsx(AddressInfo, null), __jsx(TokenInfos, null), __jsx(Controls, null));
};
export default InfoModal;