import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { ArbitrumOne, ArbitrumRinkeby, Mainnet, Sepolia, Base, BSC, Avalanche, Polygon, Cronos, Fantom, Optimism, zkSync, Klaytn, Moonbeam, Gnosis, Aurora, WCMainnet, WCSepolia, WCArbitrumOne, WCArbitrumRinkeby } from "../model/chain/ethereum";
export var ALL_SUPPORTED_CHAINS = [Mainnet, Sepolia, ArbitrumOne, ArbitrumRinkeby, Base, BSC, Optimism, Polygon, zkSync];
export var ALL_SUPPORTED_WC_CHAINS = [WCMainnet, WCSepolia, WCArbitrumOne, WCArbitrumRinkeby, Base, BSC, Optimism, Polygon, zkSync];
export var EXTRA_CONNECT_CHAINS = [Avalanche, Cronos, Fantom, Klaytn, Moonbeam, Gnosis, Aurora];
export var ALL_SUPPORTED_CHAIN_IDS = ALL_SUPPORTED_CHAINS.map(chain => chain.chainId);
export var EXTRA_CONNECT_CHAIN_IDS = EXTRA_CONNECT_CHAINS.map(chain => chain.chainId);
export var ALL_SUPPORTED_RPC_URLS = ALL_SUPPORTED_CHAINS.reduce((prev, next) => _objectSpread(_objectSpread({}, prev), {}, {
  [next.chainId]: next.rpcUrl
}), {});
export var SupportedChainId;

(function (SupportedChainId) {
  SupportedChainId[SupportedChainId["Mainnet"] = 1] = "Mainnet";
  SupportedChainId[SupportedChainId["Sepolia"] = 11155111] = "Sepolia";
  SupportedChainId[SupportedChainId["ArbitrumOne"] = 42161] = "ArbitrumOne";
  SupportedChainId[SupportedChainId["ArbitrumRinkeby"] = 421611] = "ArbitrumRinkeby";
  SupportedChainId[SupportedChainId["Base"] = 8453] = "Base";
  SupportedChainId[SupportedChainId["BSC"] = 56] = "BSC";
  SupportedChainId[SupportedChainId["Optimism"] = 10] = "Optimism";
  SupportedChainId[SupportedChainId["Polygon"] = 137] = "Polygon";
  SupportedChainId[SupportedChainId["zkSync"] = 324] = "zkSync";
})(SupportedChainId || (SupportedChainId = {}));

export var ALL_SUPPORTED_WC_RPC_URLS = ALL_SUPPORTED_WC_CHAINS.reduce((prev, next) => _objectSpread(_objectSpread({}, prev), {}, {
  [next.chainId]: next.rpcUrl
}), {});
export var DEFAULT_RPC_PROVIDERS = {
  [SupportedChainId.Mainnet]: new StaticJsonRpcProvider(Mainnet.rpcUrl),
  [SupportedChainId.Sepolia]: new StaticJsonRpcProvider(Sepolia.rpcUrl),
  [SupportedChainId.ArbitrumOne]: new StaticJsonRpcProvider(ArbitrumOne.rpcUrl),
  [SupportedChainId.ArbitrumRinkeby]: new StaticJsonRpcProvider(ArbitrumRinkeby.rpcUrl),
  [SupportedChainId.Base]: new StaticJsonRpcProvider(Base.rpcUrl),
  [SupportedChainId.BSC]: new StaticJsonRpcProvider(BSC.rpcUrl),
  [SupportedChainId.Optimism]: new StaticJsonRpcProvider(Optimism.rpcUrl),
  [SupportedChainId.Polygon]: new StaticJsonRpcProvider(Polygon.rpcUrl),
  [SupportedChainId.zkSync]: new StaticJsonRpcProvider(zkSync.rpcUrl)
};