import { useWeb3React } from '@web3-react/core';
import { useUpdaterChainId } from "./";
import { TokenlonSDK, ALL_SUPPORTED_CHAINS } from '@tokenlon/v6-sdk';
import { TokenlonEnv, ENV } from "../constants/env";
import { createPublicClient, http } from 'viem';
import EthereumProvider from 'eip1193-provider';
import { getChainById } from "../utils/chain";
export var useV6SDK = () => {
  var _provider$provider;

  var _useWeb3React = useWeb3React(),
      provider = _useWeb3React.provider;

  var expectChainId = useUpdaterChainId();
  var rpcUrl = getChainById(expectChainId).rpcUrl;
  var env = ENV.production ? TokenlonEnv.production : ENV.staging ? TokenlonEnv.staging : TokenlonEnv.development;
  var eip1193Provider = new EthereumProvider(rpcUrl);
  var client = createPublicClient({
    chain: ALL_SUPPORTED_CHAINS.find(c => c.id === expectChainId),
    transport: http(rpcUrl, {
      batch: true
    })
  });
  var sdk = new TokenlonSDK({
    chain: expectChainId,
    env,
    client,
    provider: (_provider$provider = provider === null || provider === void 0 ? void 0 : provider.provider) !== null && _provider$provider !== void 0 ? _provider$provider : eip1193Provider,
    rpcUrl
  });
  return sdk;
};