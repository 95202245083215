import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import { HOST } from "../../constants/host";
export var Metas = _ref => {
  var openGraphData = _ref.openGraphData;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("title", null, t('meta_common_title')), __jsx("meta", {
    name: "description",
    content: t('meta_common_description')
  }), __jsx("meta", {
    name: "keywords",
    content: t('meta_common_keywords')
  }), __jsx("meta", {
    name: "apple-mobile-web-app-capable",
    content: "yes"
  }), __jsx("meta", {
    name: "apple-mobile-web-app-status-bar-style",
    content: "black"
  }), __jsx("meta", {
    name: "format-detection",
    content: "telephone=no, email=no"
  }), __jsx("meta", {
    name: "renderer",
    content: "webkit"
  }), __jsx("meta", {
    httpEquiv: "X-UA-Compatible",
    content: "IE=edge"
  }), __jsx("meta", {
    name: "HandheldFriendly",
    content: "true"
  }), __jsx("meta", {
    name: "MobileOptimized",
    content: "320"
  }), __jsx("meta", {
    name: "screen-orientation",
    content: "portrait"
  }), __jsx("meta", {
    name: "x5-orientation",
    content: "portrait"
  }), __jsx("meta", {
    name: "full-screen",
    content: "yes"
  }), __jsx("meta", {
    name: "x5-fullscreen",
    content: "true"
  }), __jsx("meta", {
    name: "browsermode",
    content: "application"
  }), __jsx("meta", {
    name: "x5-page-mode",
    content: "app"
  }), __jsx("meta", {
    name: "msapplication-tap-highlight",
    content: "no"
  }), __jsx("meta", {
    httpEquiv: "Content-Type",
    content: "text/html; charset=utf-8"
  }), __jsx("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1, user-scalable=no,maximum-scale=1,minimum-scale=1,viewport-fit=cover"
  }), __jsx("meta", {
    name: "format-detection",
    content: "telephone=no"
  }), __jsx("meta", {
    property: "og:site_name",
    content: "Tokenlon"
  }), __jsx("meta", {
    property: "og:type",
    content: "website"
  }), __jsx("meta", {
    property: "og:url",
    content: HOST,
    key: "ogurl"
  }), __jsx("meta", {
    property: "og:title",
    content: t('meta_common_title'),
    key: "ogtitle"
  }), __jsx("meta", {
    property: "og:description",
    content: t('meta_common_description'),
    key: "ogdesc"
  }), __jsx("meta", {
    property: "og:image",
    content: HOST + '/images/tokenlon-og-banner.jpg',
    key: "ogimage"
  }), __jsx("meta", {
    name: "twitter:card",
    content: "summary_large_image"
  }), __jsx("meta", {
    name: "twitter:site",
    content: "@tokenlon"
  }), __jsx("meta", {
    name: "twitter:creator",
    content: "@tokenlon"
  }), __jsx("meta", {
    name: "twitter:image",
    content: HOST + '/images/tokenlon-og-banner.jpg',
    key: "twimage"
  }), openGraphData.map(og => __jsx("meta", og)), __jsx("meta", {
    name: "google-site-verification",
    content: "IfC5Dz1e3PES5nemP0p6Ypqo5mdtAMNrK-RoHUCKRo8"
  }), __jsx("meta", {
    name: "google-site-verification",
    content: "4qI7VAtA9S0LS7eD6zSpjR23yRtHe-ySYon5gEw9Ap4"
  })));
};
export default Metas;