export var INPUT_MODE;

(function (INPUT_MODE) {
  INPUT_MODE["MAKER"] = "maker";
  INPUT_MODE["TAKER"] = "taker";
})(INPUT_MODE || (INPUT_MODE = {}));

export var TradeDirection;

(function (TradeDirection) {
  TradeDirection["Input"] = "input";
  TradeDirection["Output"] = "output";
})(TradeDirection || (TradeDirection = {}));

export var RateState;

(function (RateState) {
  RateState["IDLE"] = "IDLE";
  RateState["LOADING"] = "LOADING";
  RateState["NetworkReason"] = "NetworkReason";
  RateState["OK"] = "OK";
  RateState["REFRESH_LOADING"] = "REFRESH_LOADING";
})(RateState || (RateState = {}));

export var PROTOCOL_TYPE;

(function (PROTOCOL_TYPE) {
  PROTOCOL_TYPE["AMM"] = "AMM";
  PROTOCOL_TYPE["PMM"] = "PMM";
})(PROTOCOL_TYPE || (PROTOCOL_TYPE = {}));

export var PermitSource;

(function (PermitSource) {
  PermitSource[PermitSource["TokenlonAllowanceTarget"] = 0] = "TokenlonAllowanceTarget";
  PermitSource[PermitSource["Token"] = 1] = "Token";
  PermitSource[PermitSource["TokenPermit"] = 2] = "TokenPermit";
  PermitSource[PermitSource["Permit2AllowanceTransfer"] = 3] = "Permit2AllowanceTransfer";
  PermitSource[PermitSource["Permit2SignatureTransfer"] = 4] = "Permit2SignatureTransfer";
})(PermitSource || (PermitSource = {}));